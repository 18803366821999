import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Reviews">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Reviews"
                    Subheader="Reviews"
                    bgimg={`${rpdata?.gallery?.[50]}`}
                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10 text-black">{rpdata?.labels?.general?.titleReviews}</h1>

                <review-widget  widgetId="eaed9cc9-bfa5-43a2-ab90-58dce2adaaf9"></review-widget>
            </div>
        </BaseLayout>
    );
}

export default Reviews;
